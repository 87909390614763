<template>
<div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeLoadingDialog" />
    <v-container class="container--fluid grid-list-md text-center">
        <h1 v-if="reportType === 'merchant-reports'">Merchant Reports</h1>
        <h1 v-else>Payment Reports</h1>
    </v-container>
    <v-card elevation="2" outlined shaped tile padding="20px">
        <v-row class="ma-1">
            <v-col cols="12" md="5">
                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="5">
                <v-menu v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                    <template v-slot:activator="{ on }">
                        <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                    </template>
                    <v-date-picker locale="en-in" v-model="toDateVal" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                        <v-btn text color="primary" @click="clearToDate()">Clear
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <v-card-actions elevation="2" class="container--fluid grid-list-md text-center">
                    <v-btn dark medium color=success @click="queryData(true)" data-cy="button-search">
                        <v-icon dark left> mdi-magnify</v-icon>Search
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row class="ma-1" v-if="reportType === 'merchant-reports' && isNotMerchant">
            <v-col cols="12" md="6">
                <v-autocomplete
                    clearable chips
                    :items="merchantList" 
                    item-text="name" 
                    item-value="merchantId" 
                    label="Merchant" 
                    data-cy="module-merchant-id"
                    v-model="selectedMerchant" 
                    return-object
                    @change="searchMerchant">
                </v-autocomplete>
            </v-col>
        </v-row>
    </v-card>

    <v-data-table :hide-default-header="true" :headers="headers" :items="displayReports"
        :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options"
        :server-items-length="totalReports" class="mb-14"
        :footer-props="{'items-per-page-options': [10, 20, 50]}">
        <template v-slot:item.controls="props">
            <v-btn fab dark x-small color="green" @click="download(props.item)" data-cy="button-download">
                <v-icon dark medium>mdi-cloud-download</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import SwitchAPI from '@/api/SwitchAPI'
import moment from 'moment';
import Loading from '@/views/components/Loading.vue'

export default {
    name: 'Reports',
    components: {
        Loading,
    },
    data: () => ({
        reportType: '',
        headers: [],
        records: [],
        isNotMerchant: false,
        merchantList: [],
        merchants: [],
        selectedMerchant: {},
        loading: true,
        fromDateMenu: false,
        fromDateVal: "",
        toDateMenu: false,
        toDateVal: "",
        minDate: "1997-01-01",
        maxDate: "",
        fromMax: "",
        toMin: "",
        page: 1,
        totalReports: 0,
        numberOfPages: 0,
        options: {
            rowsPerPage: 10
        },
        offset: 0,
        limit: 0,
        displayReports: [],
        remainderReports: [],
        loadingMessage: "",
        loadingDialog: false,
        loadingFinished: false,
        isHtml: false,
    }),
    computed: {
        ...mapGetters([
            'attributes'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
            // format/do something with date
        },
        toDateDisp() {
            this.fromMax = moment().format(this.toDateVal)
            return this.toDateVal;
            // format/do something with date
        },
    },
    watch: {
        options: {
            handler() {
                console.log('options');
                this.queryData(false);
                this.setData();
            },
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        deep: true,
    },
    async mounted() {
        console.log('mounted')
        this.reportType = this.$route.name
        this.setHeader()
        this.setDefaultDateRange()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async setData() {
            try {
                const listResponse = await SwitchAPI.getUserMerchants()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.merchants = listResponse.merchants
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var transactionList = undefined

                const parameters = {
                    offset: pageNumber,
                    limit: itemsPerPage,
                    merchantID: this.selectedMerchant ? this.selectedMerchant.merchantId ?? '' : '',
                    dateFrom: this.fromDateVal,
                    dateTo: this.toDateVal
                }

                if (this.reportType === "payment-reports") {
                    console.log("Admin Transactions")
                    transactionList = await SwitchAPI.listPaymentReports(parameters)
                } else {
                    console.log("Merchant Transactions")
                    transactionList = await SwitchAPI.listMerchantTransactionReports(parameters)
                }
                if (transactionList.error) {
                    //error getting data
                    console.log(`${transactionList.error}`)
                } else {
                    this.records = this.reportType === "payment-reports" ? transactionList.paymentReports : transactionList.merchantTransactionalReports
                    var filteredList = []
                    this.isNotMerchant = transactionList.isNotMerchant
                    if (page > 1 && this.remainderReports.length > 0) {
                        filteredList = this.remainderReports.concat(this.records)
                    } else {
                        filteredList = Object.assign(this.records)
                    }
                    this.totalReports = transactionList.totalItems
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayReports = filteredList.slice(0, end)
                    this.remainderReports = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = transactionList.pageCount

                    // if(this.totalReports > 0) {
                    //     var hasMoreResponse = undefined
                    //     const parameters = {
                    //         offset: this.totalReports,
                    //         limit: 1,
                    //         merchantID: this.selectedMerchant ? this.selectedMerchant.merchantId ?? '' : '',
                    //         dateFrom: this.fromDateVal,
                    //         dateTo: this.toDateVal
                    //     }
                    //     if (this.reportType === "payment-reports") {
                    //         hasMoreResponse = await SwitchAPI.listPaymentReports(parameters)
                    //     } else {
                    //         hasMoreResponse = await SwitchAPI.listMerchantTransactionReports(parameters)
                    //     }
                    //     if (!hasMoreResponse || hasMoreResponse.error) {
                    //         //error getting data
                    //         console.log(`${hasMoreResponse.error}`)
                    //     } else {
                    //         var hasMoreList = this.reportType === "payment-reports" ? hasMoreResponse.paymentReports : hasMoreResponse.merchantTransactionalReports
                    //         if(hasMoreList.length > 0) {
                    //             this.totalReports = Math.floor(this.totalReports/10) * 10 + 1
                    //             this.numberOfPages = this.numberOfPages + 1
                    //         }
                    //     }
                    // }
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        //method to update the Merchant query
        searchMerchant: async function (val) {
            this.selectedMerchant = val
            // this.queryData(true)
        },
        setHeader() {
            var headers = [{
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'File Name',
                    value: 'outputFilename'
                },
                {
                    text: 'File Folder',
                    value: 'outputFolder'
                },
                {
                    text: 'Transaction Date',
                    value: 'transactionDate'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                }
            ]
            if (this.reportType === 'merchant-reports') {
                headers.push({
                    text: 'Merchant',
                    value: 'merchant'
                })

            } else {
                headers = headers.filter(header => header.text != 'Merchant')
            }
            headers.push({
                text: 'Actions',
                value: 'controls',
                sortable: false
            })
            this.headers = headers
        },
        async download(item) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.loadingMessage = `Downloading...`
            const downloadResponse = await SwitchAPI.downloadReport(item.outputFolder, item.outputFilename, this.reportType)
            this.loadingFinished = true
            if (!downloadResponse || downloadResponse.error) {
                this.isHtml = false
                this.loadingMessage = downloadResponse ? downloadResponse.error : "Error downloading file."
            } else {
                this.loadingMessage = `Download complete.`
            }
        },
        closeLoadingDialog(type) {
            this.loadingDialog = false
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today
        },
        setDefaultDateRange() {
            this.fromDateVal = this.getToday()
            this.toDateVal = ''
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.fromDateVal)
        },
        setDefaultData() {
            this.records = []
        },
        clearToDate() {
            this.toDateVal = ''
            this.toDateMenu = false
        }
    }
};
</script>
